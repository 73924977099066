import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Scrollable, ScoreHighlight, NonScrollable, TabPanel, Loading } from 'components';
import { LineChart, Line, XAxis, YAxis, Tooltip as RETooltip, CartesianGrid, Legend } from 'recharts';
import dayjs from 'dayjs';
import { gql, useQuery } from '@apollo/client';
import { Tabs, Tab, Button, Grid, Box, useTheme } from '@mui/material';
import { ReactComponent as BuildingIcon } from 'assets/business-building.svg';
import { ReactComponent as TestBuyIcon } from 'assets/shopping-cart.svg';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import { ReactComponent as BlankImage } from 'assets/blank-image.svg';
import { formatDate, roundToNearest30 } from 'utils/dates';
import { AuthDataContext, ToastDataContext, RegionDataContext } from 'contexts';
import PurchaseTestBuy from 'pages/TestBuys/TestBuyPurchase/PurchaseTestBuy';
import SellerAndOfferMenu from 'pages/Common/SellerAndOfferMenu';
import { NavLink } from 'react-router-dom';
import OfferIcons from 'pages/Common/OfferIcons';
import { isAmazonSeller } from 'utils/misc';
import OfferScore from 'pages/AsinManager/OfferScore';

const HistoryItem = ({ title, data, dataKey, color, fixedLength, currency }) => {
  const theme = useTheme();

  const cleanedValue = (v) => {
    if (v) {
      let value = v;
      value = `${value.toFixed(fixedLength || 0)}`;
      if (currency) {
        value = `${currency}${value}`;
      }
      return value;
    }
    return 0;
  };

  const getChartData = ({ history }) =>
    history.map((h) => ({ x: new Date(h.date), y: h[dataKey] })).reverse();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <LineChart
            margin={{ top: 15, right: 10, left: 10, bottom: 15 }}
            width={700}
            height={200}
            data={getChartData({ history: data })}
          >
            <XAxis dataKey="x" tickFormatter={(v) => dayjs(v).format('M/D')} />
            <YAxis dataKey="y" />
            <RETooltip
              label={title}
              formatter={(v) => [`${cleanedValue(v)}`, `${title}`]}
              labelFormatter={(v) => dayjs(v).format('M/D/YYYY h:mm a')}
            />
            <Line
              strokeWidth={2}
              dataKey="y"
              stroke={color || theme.palette.primary.main}
              activeDot={{ r: 6 }}
              dot={false}
            />
            <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#bbb" />
          </LineChart>
        </Grid>
      </Grid>
    </>
  );
};

const WinRatePriceHistoryItem = ({ winRateData, priceData, region, regionCurrency }) => {
  const theme = useTheme();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            padding: '15px 20px',
            bgcolor: 'white',
            border: '1px solid #aaa',
            borderRadius: '6px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500
          }}
        >
          <Box>{dayjs(label).format('M/D/YYYY')}</Box>
          {payload[0] && (
            <Box sx={{ color: theme.colors.linkBlue }}>
              {`Win Rate: ${payload[0].value?.toFixed(0)}%`}
            </Box>
          )}
          {payload[1] && (
            <Box sx={{ color: theme.colors.green }}>
              {`Price: ${Intl.NumberFormat(region, {
                style: 'currency',
                currency: regionCurrency,
                maximumFractionDigits: 2,
              }).format(payload[1].value)}`}
            </Box>
          )}
        </Box>
      );
    }

    return null;
  };

  const getChartData = () => {
    const getPrice = (d) => {
      for (let i = 0; i < priceData.length; i += 1) {
        const pricePoint = priceData[i];
        if (pricePoint.date === d) {
          return pricePoint.price;
        }
      }
      return null;
    }
    const data = winRateData.map((h) => (
      { x: new Date(h.date),
        "Win Rate": h.score,
        "Price": getPrice(h.date) }
    )).reverse();
    return data;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <LineChart
            margin={{ top: 15, right: 10, left: 10, bottom: 15 }}
            width={700}
            height={200}
            data={getChartData()}
          >
            <XAxis dataKey="x" tickFormatter={(v) => dayjs(v).format('M/D')} />
            <YAxis
              yAxisId="left"
              dataKey="Win Rate"
              tickFormatter={(v) => `${v}%`}
              stroke={theme.colors.linkBlue}
            />
            <YAxis
              yAxisId="right"
              orientation='right'
              dataKey="Price"
              tickFormatter={(v) => Intl.NumberFormat(region, {
                style: 'currency',
                currency: regionCurrency,
                maximumFractionDigits: 0,
              }).format(v?.toFixed(0))}
              stroke={theme.colors.green}
            />
            <RETooltip
              // label={title}
              // formatter={(v) => [`${cleanedValue(v)}`, `${title}`]}
              labelFormatter={(v) => dayjs(v).format('M/D/YYYY')}
              content={<CustomTooltip />}
            />
            <Line
              yAxisId="left"
              strokeWidth={2}
              dataKey="Win Rate"
              stroke={theme.colors.linkBlue}
              activeDot={{ r: 6 }}
              dot={false}
            />
            <Line
              yAxisId="right"
              strokeWidth={2}
              dataKey="Price"
              stroke={theme.colors.green}
              activeDot={{ r: 6 }}
              dot={false}
            />
            <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#bbb" />
            <Legend />
          </LineChart>
        </Grid>
      </Grid>
    </>
  );
};

const OfferContent = ({ hide, offerId, seller, asin }) => {
  const [tab, setTab] = useState(0);
  const [offer, setOffer] = useState(null);
  const [referenceAddresses, setReferenceAddresses] = useState(null);
  const [testBuyOpen, setTestBuyOpen] = useState(false);
  const [stockEstimationHistory, setStockEstimationHistory] = useState(null);
  const [winRateHistory, setWinRateHistory] = useState(null);
  const [priceHistory, setPriceHistory] = useState(null);
  const currentScore = offer?.currentScore;
  const isAmazon = isAmazonSeller({ sellerId: seller?.id, sellerName: seller?.name });
  const { account, region, hasAbility } = useContext(AuthDataContext);
  const theme = useTheme();
  const { setToast } = useContext(ToastDataContext);
  const { regionUrl, regionCurrency } = useContext(RegionDataContext);

  const GET_OFFER_QUERY = gql`
    query GetOffer(
      $id: String!
      $asin: String
      $sellerId: String
      $dateStart: DateTime
      $dateEnd: DateTime
    ) {
      getOffer(offerId: $id) {
        asin
        sellerId
        buyBoxWinPercentage
        categoryId
        name
        offerId
        price
        mapPrice
        variantTitle
        fulfilledByAmazon
        image
        shippingPrice
        shippingDays
        harvestDate
        stockEstimation
        rule1threshold
        rule1actual
        rule2threshold
        rule2actual
        rule3threshold
        rule3actual
        rule4threshold
        rule4actual
        rule5threshold
        rule5actual
        rule6threshold
        rule6actual
        rule7threshold
        rule7actual
        rule8threshold
        rule8actual
        rule9threshold
        rule9actual
        rule10threshold
        rule10actual
        currentScore {
          score
        }
        winRateHistory {
          score
          date
        }
        priceHistory {
          price
          date
        }
      }
      getAddresses {
        id
        accountId
        addressLine1
        addressLine2
        city
        state
        zip
        country
        addressTypeId
        addressTypeName
        name
        description
        coordinates
      }
      getStockEstimationHistoryForAsinAndSeller(
        asin: $asin
        sellerId: $sellerId
        dateStart: $dateStart
        dateEnd: $dateEnd
      ) {
        score
        date
      }
    }
  `;

  const maxDate = new Date().getTime();
  const minDate = maxDate - 30 * 24 * 60 * 60 * 1000;
  const dateStart = roundToNearest30(new Date(minDate)).toISOString();
  const dateEnd = roundToNearest30(new Date(maxDate)).toISOString();

  const processData = (dataToProcess) => {
    const chartMinTime = (new Date(maxDate - 90 * 24 * 60 * 60 * 1000)).getTime();
    setOffer(dataToProcess.getOffer);
    setReferenceAddresses(dataToProcess.getAddresses);
    let sortedStockHistory = dataToProcess.getStockEstimationHistoryForAsinAndSeller
      ?.concat()
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    sortedStockHistory = sortedStockHistory.filter(a => {
      const time1 = (new Date(a.date)).getTime();
      return time1 > chartMinTime;
    });
    setStockEstimationHistory(sortedStockHistory);
    let sortedWinRateHistory = dataToProcess.getOffer.winRateHistory
      ?.concat()
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    sortedWinRateHistory = sortedWinRateHistory.filter(a => {
      const time1 = (new Date(a.date)).getTime();
      return time1 > chartMinTime;
    });
    setWinRateHistory(sortedWinRateHistory);

    let sortedPriceHistory = dataToProcess.getOffer.priceHistory
      ?.concat()
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    sortedPriceHistory = sortedPriceHistory.filter(a => {
      const time1 = (new Date(a.date)).getTime();
      return time1 > chartMinTime;
    });
    setPriceHistory(sortedPriceHistory);
  };

  const { loading } = useQuery(GET_OFFER_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { id: offerId, asin, sellerId: seller.id, dateStart, dateEnd },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
    onCompleted: processData,
  });

  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  return (
    <>
      {loading && <Loading />}
      {!hide && !loading && offer && referenceAddresses && (
        <Box sx={{ fontFamily: theme.typography.fontFamily }}>
          <NonScrollable sx={{ pb: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                <Box sx={{ display: 'flex', minHeight: '140px', alignItems: 'center' }}>
                  {offer.image && (
                    <Box
                      component="img"
                      src={`${offer.image}`}
                      alt="product"
                      sx={{ borderRadius: '6px', maxWidth: '140px', maxHeight: '140px' }}
                    />
                  )}
                  {!offer.image && <BlankImage style={{ width: '140px', height: '140px' }} />}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    height: '100%',
                    justifyContent: 'center',
                    maxWidth: '70%',
                    minWidth: '70%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                      <Box sx={{ fontSize: '14px', fontWeight: '400', color: 'greys.darkGrey' }}>
                        Health Index
                      </Box>
                      <Box sx={{ fontSize: '12px', fontWeight: '400', color: 'greys.silver' }}>
                        {`LAST UPDATED: ${formatDate(offer.harvestDate)}`}
                      </Box>
                    </Box>
                    <Box sx={{ fontSize: '18px', fontWeight: '600', color: '#9B57D3' }}>
                      {Intl.NumberFormat(region, {
                        style: 'currency',
                        currency: regionCurrency,
                      }).format(offer.price?.toFixed(2))}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      justifyContent: 'space-between',
                    }}
                  >
                    <ScoreHighlight value={currentScore?.score} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <Box sx={{ fontSize: '14px' }}>
                        Buy Box Win Rate: {offer.buyBoxWinPercentage}%
                      </Box>
                      <Box sx={{ fontSize: '14px' }}>
                        {account.fetchInventory && (
                          <Box>
                            Inventory: {offer.stockEstimation !== null && offer.stockEstimation}
                            {offer.stockEstimation === null && 'Pending'}
                          </Box>
                        )}
                        {!account.fetchInventory && (
                          <Box>
                            Inventory:&nbsp;
                            <NavLink to="/settings/plan" style={{ textDecoration: 'underline' }}>
                              Upgrade
                            </NavLink>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <BuildingIcon />
                    <Box sx={{ fontSize: '14px', fontWeight: '600' }}>{seller.name}</Box>
                  </Box>
                  <Box sx={{ fontSize: '17px', fontWeight: '700', color: 'greys.darkGrey' }}>
                    {offer.name}
                    {offer.variantTitle && (
                      <Box component="span" sx={{ color: 'greys.grey', fontWeight: '500' }}>
                        {` ${offer.variantTitle}`}
                      </Box>
                    )}
                    <OfferIcons offer={offer} />
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      sx={{ borderColor: 'greys.lightGrey' }}
                      variant="outlined"
                      onClick={() => {
                        window.open(`${regionUrl}/dp/${offer.asin}`);
                      }}
                    >
                      View on Amazon
                    </Button>
                    {!isAmazon && (
                      <>
                        <Button
                          sx={{ borderColor: 'greys.lightGrey' }}
                          variant="outlined"
                          onClick={() => {
                            window.open(`${regionUrl}/sp?_encoding=UTF8&seller=${seller.id}`);
                          }}
                        >
                          Visit Seller
                        </Button>
                        {hasAbility('makeTestBuy') && (
                          <Button
                            startIcon={<TestBuyIcon />}
                            onClick={() => {
                              setTestBuyOpen(true);
                            }}
                          >
                            Test Buy
                          </Button>
                        )}
                        <SellerAndOfferMenu
                          button={
                            <Button
                              sx={{
                                borderColor: 'greys.lightGrey',
                                p: 0,
                                maxWidth: '40px',
                                minWidth: '40px',
                              }}
                              variant="outlined"
                            >
                              <MoreHorizIcon />
                            </Button>
                          }
                          seller={seller}
                          offers={[offer]}
                          isOfferProfile
                          sx={{ maxHeight: '40px' }}
                        />
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              <Tabs
                sx={{ mt: 3 }}
                color="primary"
                value={tab}
                onChange={(_event, value) => handleTabChange(value)}
              >
                <Tab label="Scoring" />
                <Tab label="Inventory" />
                <Tab label="Win Rate & Price" />
              </Tabs>
            </Box>
          </NonScrollable>
          <Scrollable
            transparent
            sx={{
              mt: 1.5,
              ml: 1,
              mr: 1,
              mb: 1,
            }}
          >
            <TabPanel value={tab} index={0}>
              <OfferScore offer={offer} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Box
                sx={{ m: 1, p: 3, bgcolor: 'greys.white', borderRadius: theme.largeBorderRadius }}
              >
                <Box sx={{ mb: 2, fontSize: '14px', fontWeight: '600' }}>Inventory History</Box>
                {account.fetchInventory &&
                  stockEstimationHistory &&
                  stockEstimationHistory.length > 0 && (
                    <HistoryItem
                      title="Inventory"
                      data={stockEstimationHistory}
                      dataKey="score"
                      color={theme.palette.primary.main}
                      fixedLength={0}
                    />
                  )}
                {account.fetchInventory &&
                  stockEstimationHistory &&
                  stockEstimationHistory.length === 0 && (
                    <Box sx={{ fontSize: '14px' }}>Inventory history pending</Box>
                  )}
                {account.fetchInventory && !stockEstimationHistory && (
                  <Box sx={{ fontSize: '14px' }}>Inventory history pending</Box>
                )}
                {!account.fetchInventory && (
                  <NavLink
                    to="/settings/plan"
                    style={{ fontSize: '14px', textDecoration: 'underline' }}
                  >
                    Contact sales to get inventory
                  </NavLink>
                )}
              </Box>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Box
                sx={{ m: 1, p: 3, bgcolor: 'greys.white', borderRadius: theme.largeBorderRadius }}
              >
                <Box sx={{ mb: 2, fontSize: '14px', fontWeight: '600' }}>Win Rate & Price History</Box>
                {winRateHistory && winRateHistory.length > 0 && (
                  <WinRatePriceHistoryItem
                    winRateData={winRateHistory}
                    priceData={priceHistory}
                    region={region}
                    regionCurrency={regionCurrency}
                  />
                )}
              </Box>
            </TabPanel>
          </Scrollable>
          {testBuyOpen && (
            <PurchaseTestBuy
              offerId={offer.offerId}
              sellerId={seller.id}
              onClose={() => setTestBuyOpen(false)}
            />
          )}
        </Box>
      )}
    </>
  );
};

OfferContent.propTypes = {
  hide: PropTypes.bool,
  offerId: PropTypes.string.isRequired,
  seller: PropTypes.oneOfType([PropTypes.object]).isRequired,
  asin: PropTypes.string.isRequired,
};

OfferContent.defaultProps = {
  hide: false,
};

export default OfferContent;
