import React, {  } from 'react';
import { Grid, Box, useTheme } from '@mui/material';

const HistoryAboutItem = ({ title, info }) => (
  <>
    <Box sx={{ fontSize: '14px', fontWeight: '600' }}>{title}</Box>
    <Box sx={{ fontSize: '14px', fontWeight: '400' }}>{info}</Box>
  </>
);


const AsinScore = ({ asin }) => {
  const theme = useTheme();

  const ruleTypes = [
    { id: 1, description: 'Offers below MAP price' },
    { id: 2, description: 'Low-scoring sellers' },
    { id: 3, description: 'Unauthorized foreign sellers' },
    { id: 4, description: 'Previously actioned sellers' },
  ];

  const getValueString = (ruleType) => {
    const limit = asin[`rule${ruleType.id}threshold`];
    const value = asin[`rule${ruleType.id}actual`];
    const badValue = value >= limit;
    let typeString = (ruleType.id === 2 || ruleType.id === 3 || ruleType.id === 4) ? 'seller' : 'offer';
    if (limit > 1) {
      typeString += 's';
    }
    return (
      <Box sx={{ display: 'flex', gap: '4px' }}>
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {asin[`rule${ruleType.id}actual`] !== null || Number.isNaN(asin[`rule${ruleType.id}actual`]) ? asin[`rule${ruleType.id}actual`] : 'N/A'}
        </Box>
        <Box>
          {'(<'}
          {asin[`rule${ruleType.id}threshold`] !== null || Number.isNaN(asin[`rule${ruleType.id}threshold`]) ? asin[`rule${ruleType.id}threshold`] : 'N/A'}
          {` ${typeString})`}
        </Box>
      </Box>
    );
  };

  const noScoringAvailable = () => {
    let found = 0;
    for (let i = 0; i < ruleTypes.length; i += 1) {
      if (asin[`rule${ruleTypes[i].id}threshold`] !== null) {
        found = 1;
      }
    }
    return !found;
  }

  return (
    <>
      {asin && ruleTypes && (
        <Box sx={{ m: 1, p: 3, bgcolor: 'greys.white', borderRadius: theme.largeBorderRadius, width: '500px' }}>
          <Grid container item direction="row" spacing={2}>
            {ruleTypes.map(r => {
              if (asin[`rule${r.id}threshold`] === null) {
                return null;
              }
              return (
                <Grid key={r.id} item xs={6}>
                  <HistoryAboutItem
                    title={r.description}
                    info={
                      <Box sx={{ display: 'flex' }}>
                        {getValueString(r)}
                      </Box>
                    }
                  />
                </Grid>
              )
            })}
            {noScoringAvailable() && (
              <Grid item xs={12} sx={{ fontSize: '14px', color: 'greys.darkGrey' }}>
                No recent scoring information available
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AsinScore;
