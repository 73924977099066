import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Link,
  useTheme,
  Box,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { Chip, LoadingButton, ProductName, ConfirmationDialog } from 'components';
import { NavLink } from 'react-router-dom';
import { formatDateTime, formatDate } from 'utils/dates';
import { ReactComponent as DownloadIcon } from 'assets/big-download.svg';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ToastDataContext, AuthDataContext } from 'contexts';
import { saveAs } from 'file-saver';
import {
  getColor,
  getBackgroundColor,
  getActionDescription,
  getActionDescriptionShort,
  getTemplateDescription,
} from 'utils/enforcements';
import EditActionFieldsDialog from 'pages/Common/EditActionFieldsDialog';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { Buffer } from 'buffer';
import CustomFieldDisplay from 'pages/Common/CustomFieldDisplay';
import TestBuyDetail from 'pages/TestBuys/TestBuyList/TestBuyDetail';

const ActionDetailListItem = ({ action, actionTypes, enforcement, showOffers, onRemoved, onUpdated }) => {
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [creatingPDF, setCreatingPDF] = useState(false);
  const theme = useTheme();
  const { setToast } = useContext(ToastDataContext);
  const { hasPermission } = useContext(AuthDataContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editAction, setEditAction] = useState(null);
  const [deleteConfirmationAction, setDeleteConfirmationAction] = useState(null);
  const [deletingAction, setDeletingAction] = useState(false);

  const REMOVE_ACTION_MUTATION = gql`
    mutation RemoveActionItem($id: ID!) {
      removeEnforcementActionItem(id: $id)
    }
  `;

  const [removeActionMutation] = useMutation(REMOVE_ACTION_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });


  const GET_PDF_QUERY = gql`
    query GetPdf($id: ID!) {
      getPdfForAction(id: $id) {
        content
        date
      }
    }
  `;

  const [getPdf] = useLazyQuery(GET_PDF_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
    onCompleted: (d) => {
      const e = enforcement ?? action.enforcement;
      const buf = Buffer.from(d.getPdfForAction.content, 'base64');
      const blob = new Blob([buf], { type: 'application/pdf' });
      saveAs(
        blob,
        `Letter - ${e?.offers[0].sellerName} - ${e?.offers[0].asin} - ${formatDate(
          action.updatedDate,
        )}.pdf`,
      );
      setCreatingPDF(false);
    },
  });

  const getFormSubmission = () => {
    const e = enforcement ?? action.enforcement;
    const cleanedUp = action.violationJson?.replaceAll("\\\\n", ", ").replaceAll("\n", " ");
    const json = JSON.parse(cleanedUp);

    // Encode any URLs for images
    const keys = Object.keys(json);
    for (let j = 0; j < keys.length; j += 1) {
      const key = keys[j];
      if (Array.isArray(json[key])) {
        const items = json[key];
        for (let i = 0; i < items.length; i += 1) {
          const item = items[i];
          if (item.startsWith('http')) {
            json[key][i] = encodeURI(item);
          }
        }
      }
    }

    const content = JSON.stringify(json, null, 2);

    const buf = Buffer.from(content, 'utf8');
    const blob = new Blob([buf], { type: 'text/plain' });
    saveAs(
      blob,
      `IPViolation - ${e?.offers[0].sellerName} - ${e?.offers[0].asin} - ${formatDate(
        action.updatedDate,
      )}.txt`,
    );
    setCreatingPDF(false);
  };

  const handleDownloadPDF = (e) => {
    e.preventDefault();
    setCreatingPDF(true);

    if (action.actionTaken === 1 || action.actionTaken === 6) {
      getPdf({ variables: { id: action.id } });
    } else if (action.actionTaken === 2) {
      getFormSubmission();
    }
  };

  const offers = action?.enforcement?.offers ?? enforcement?.offers;

  useEffect(() => {
    if (action.actionTaken === 1 || action.actionTaken === 6 || action.actionTaken === 2) {
      setShowDownloadButton(true);
    }
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (a) => {
    setEditAction(a);
  }

  const handleDelete = (a) => {
    setDeleteConfirmationAction(a);
  };

  const handleEditDone = (success) => {
    setEditAction(null);
    if (success && onUpdated) {
      onUpdated();
    }
  }

  const deleteAction = async (a) => {
    setDeletingAction(true);
    removeActionMutation({
      variables: { id: a.id },
      onCompleted: () => {
       onRemoved(a);
      },
    });
    setDeletingAction(false);
    setDeleteConfirmationAction(null);
  };

  const getComponentForValue = (key, value, level, hideKey = false) => {
    if (Array.isArray(value)) {
      return (
        <Box key={key} sx={{ fontSize: '11px', ml: level * 2 }}>
          <Box>
            <Box component='span' sx={{ color: 'greys.grey' }}>{key}</Box>:
          </Box>
          <Box>
            {value.map(v => getComponentForValue(key, v, level + 1, true))}
          </Box>
        </Box>
      )
    }
    return (
      <Box key={key} sx={{ fontSize: '12px', ml: level * 2 }}>
        {typeof value === 'string' && (
          <Box>
            {!hideKey && (<Box component='span' sx={{ color: 'greys.grey' }}>{key}: </Box>)}
            {value.startsWith('http') && (
              <a href={value} target="_blank" rel="noreferrer">{value}</a>
            )}
            {!value.startsWith('http') && (
              <Box component='span'>{value}</Box>
            )}
          </Box>
        )}
        {typeof value === 'boolean' && (
          <Box>
            {!hideKey && (<Box component='span' sx={{ color: 'greys.grey' }}>{key}: </Box>)}{value ? 'true' : 'false'}
          </Box>
        )}
        {(typeof value === 'object' && !Array.isArray(value) && value !== null) && (
          <Box>
            {!hideKey && (
              <Box>
                <Box component='span' sx={{ color: 'greys.grey' }}>{key}: </Box>
              </Box>
            )}
            {Object.keys(value).map(k => getComponentForValue(k, value[k], level + 1))}
          </Box>
        )}
      </Box>
    )
  }

  const getIPViolationInfo = () => {
    const cleanedUp = action.violationJson?.replaceAll("\\\\n", ", ").replaceAll("\n", " ");
    try {
      const json = JSON.parse(decodeURIComponent(cleanedUp));
      const keys = Object.keys(json);
      return (
        <Box sx={{ mt: 2 }}>
          {keys.map(k => getComponentForValue(k, json[k], 0))}
        </Box>
      );
    } catch (err) {
      console.error(err, cleanedUp);
      return <Box />
    }
  }

  return (
    <Grid
      key={action.id}
      container
      item
      sx={{
        fontFamily: theme.typography.fontFamily,
        bgcolor: 'greys.backgroundGrey',
        p: 2,
        borderRadius: '5px',
        border: `1px solid ${theme.palette.greys.lightGrey}`,
      }}
    >
      <Grid
        item
        xs={8}
        sx={{
          textAlign: 'left',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: '12px',
            fontWeight: '500',
          }}
        >
          <Chip
            sx={{ mr: 1, my: 0.5 }}
            label={getActionDescriptionShort(actionTypes, action)}
            color={getColor(action.actionTaken)}
            backgroundColor={getBackgroundColor(action.actionTaken)}
          />
          {action.actionTaken !== 6 &&
            !(action.actionTaken === 1 && action.letterType === 'standard') && (
              <Tooltip title={action.statusExtra} sx={{ cursor: 'default' }}>
                <Box>
                  <Chip
                    sx={{ mr: 1, my: 0.5 }}
                    label={action.status || 'Processing'}
                    color={getColor(10)}
                    backgroundColor={getBackgroundColor(10)}
                  />
                </Box>
              </Tooltip>
            )}
          {action.actionTaken === 6 && action.status && (
            <Box>
              <Chip
                sx={{ mr: 1, my: 0.5 }}
                label={action.status}
                color={getColor(10)}
                backgroundColor={getBackgroundColor(10)}
              />
            </Box>
          )}
          {action.actionTaken === 1 && (!action.tracking || action.letterType === 'standard') && (
            <Chip
              sx={{ mr: 1, my: 0.5 }}
              label={action.status === 'Cancelled' ? action.status : 'Tracking Not Available'}
              color={
                action.tracking ? theme.palette.chips.blue.color : theme.palette.chips.red.color
              }
              backgroundColor={
                action.tracking
                  ? theme.palette.chips.blue.backgroundColor
                  : theme.palette.chips.red.backgroundColor
              }
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={4} sx={{ textAlign: 'right' }}>
        <IconButton size="small" onClick={handleMenu}>
          <MoreHorizIcon fill={theme.palette.greys.silver} />
        </IconButton>
        {Boolean(anchorEl) && (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                handleEdit(action);
              }}
            >
              <EditIcon style={{ maxWidth: '14px', minWidth: '14px' }} />
              Edit Information
            </MenuItem>
            {hasPermission('tenantManagement') && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleDelete(action);
                }}
              >
                <DeleteIcon fill={theme.palette.chips.red.color} />
                <Typography sx={{ color: theme.palette.chips.red.color, mr: '5px' }}>
                  Delete
                </Typography>
              </MenuItem>
            )}
          </Menu>
        )}
      </Grid>
      {showOffers && offers?.length > 0 && (
        <Grid container item xs={12} sx={{ mb: showDownloadButton ? 1.5 : 0, mt: 0.5 }}>
          {showOffers && (
            <Box sx={{ maxHeight: '190px', overflow: 'scroll', width: '100%' }}>
              {offers?.map((o) => (
                <Grid key={o.id} container item sx={{ fontSize: '12px' }}>
                  <Grid item xs={2}>
                    {o.asin}
                  </Grid>
                  <Grid item xs={7}>
                    <ProductName
                      asin={o.asin}
                      productName={o.name}
                      sx={{
                        display: 'block',
                        fontWeight: '500',
                        color: '#0071DA',
                        textDecoration: 'underline',
                        textOverflow: 'ellipsis',
                        lineHeight: '18px',
                        maxHeight: '18px',
                        overflow: 'hidden',
                        wordBreak: 'break-all',
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Box sx={{ textAlign: 'right' }}>
                      <NavLink
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`/enforcements/snapshot/${o.regionId}/${o.asin}/${encodeURIComponent(o.offerId)}`}
                        style={{ textDecoration: 'underline', color: theme.palette.greys.silver }}
                      >
                        Amazon Snapshot
                      </NavLink>
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Box>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {action.actionTaken === 1 && action.tracking && (
            <Box sx={{ pt: 1, pb: 1 }}>
              <Link
                href={`https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${action.tracking}`}
                target="_blank"
                sx={{
                  textDecoration: 'underline',
                  color: theme.colors.linkBlue,
                  fontSize: '14px',
                }}
              >
                Track USPS Letter
              </Link>
            </Box>
          )}
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
            <Typography sx={{ pt: 1, color: 'greys.darkGrey', fontWeight: 'bold', fontSize: '14px' }}>
              {getActionDescription(action)}
            </Typography>
            <Typography sx={{ pt: 1, color: 'greys.darkGrey', fontWeight: '500', fontSize: '12px' }}>
              {getTemplateDescription(action)}
            </Typography>
          </Box>
          {showDownloadButton && (
            <LoadingButton
              size="small"
              sx={{ borderColor: 'greys.lightGrey', fontSize: '13px', height: '29px' }}
              variant="outlined"
              startIcon={<DownloadIcon fill={theme.palette.greys.black} />}
              onClick={handleDownloadPDF}
              loading={creatingPDF}
              disabled={creatingPDF}
            >
              {action.actionTaken === 2 ? 'Download .txt File' : 'Download PDF'}
            </LoadingButton>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'left' }}>
        <Typography sx={{ color: 'greys.silver', fontSize: '12px' }}>
          Created {formatDateTime(action.createdDate)} by {action.userName?.length > 0 ? action.userName : action.userEmail}
        </Typography>
      </Grid>
      {action.actionTaken === 3 && (
        <TestBuyDetail
          action={action}
          onRefetch={onUpdated}
        />
      )}
      {action.actionTaken !== 3 && (
        <CustomFieldDisplay
          values={action.fields}
          sx={{ mt: 1 }}
          attachedIdInt={parseInt(action.id, 10)}
          areas={['action']}
          compact
        />
      )}
      {action.actionTaken === 2 && (
        <Box>
          {getIPViolationInfo()}
        </Box>
      )}
      {editAction && (
        <EditActionFieldsDialog
          actionId={parseInt(editAction.id, 10)}
          onClose={handleEditDone}
          areas={action.actionTaken === 3 ? ['action', 'testBuy'] : ['action']}
        />
      )}
      {deleteConfirmationAction !== null && (
        <ConfirmationDialog
          open
          title="Delete enforcement action"
          message="Are you sure you wish to delete this enforcement action? THIS CANNOT BE UNDONE"
          busy={deletingAction}
          okTitle="Delete"
          destructive
          onClose={(confirmed) => {
            if (confirmed) {
              deleteAction(deleteConfirmationAction);
            } else {
              setDeleteConfirmationAction(null);
            }
          }}
        />
      )}
    </Grid>
  );
};

const EnforcementActionsList = ({
  enforcement,
  actions,
  actionTypes,
  showOffers,
  onActionTaken,
  onRemoved,
  onUpdated,
}) => (
  <Box>
    <Grid container spacing={1}>
      {actions.map((a) => (
        <Grid key={a.id} item xs={12}>
          <ActionDetailListItem
            key={a.id}
            action={a}
            actionTypes={actionTypes}
            enforcement={enforcement}
            showOffers={showOffers}
            onActionTaken={onActionTaken}
            onRemoved={onRemoved}
            onUpdated={onUpdated}
          />
        </Grid>
      ))}
    </Grid>
  </Box>
);

EnforcementActionsList.defaultProps = {
  showOffers: false,
  enforcement: null,
};

EnforcementActionsList.propTypes = {
  showOffers: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  enforcement: PropTypes.object,
  actionTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ActionDetailListItem.defaultProps = {
  showOffers: false,
  enforcement: null,
};

ActionDetailListItem.propTypes = {
  showOffers: PropTypes.bool,
  action: PropTypes.object.isRequired,
  enforcement: PropTypes.object,
  actionTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EnforcementActionsList;
