import React, { useState } from 'react';
import { Loading } from 'components';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Checkbox,
  Tooltip,
  Box,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import InfiniteScroll from 'react-infinite-scroller';
import AsinRow from './AsinRow';

const AsinTable = ({
  asins,
  setEditAsin,
  setDeleteConfirmationAsinId,
  selectAll,
  setSelectAll,
  changeChecked,
  showParent,
  onExpand,
  setSellerProfile,
  readOnly,
  filterSeller,
  showWinRate,
  onAuthorizedChanged,
  setSelectAllTable,
  someSelected,
  allAsinsMode,
  healthMode,
  showTier,
  onMovedItem
}) => {
  const scrollPageLength = 50;
  const [limit, setLimit] = useState(scrollPageLength);

  const loadMore = async () => {
    if (limit < asins.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > asins.length) {
        newLimit = asins.length;
      }
      setLimit(newLimit);
    }
  };

  const handleExpand = (asin) => {
    if (onExpand) {
      onExpand(asin);
    }
  };

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore
      loader={limit < asins.length && <Loading key={0} />}
      useWindow={false}
    >
      <TableContainer sx={{ overflowX: 'initial' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ pr: 0, pl: '12px' }} width="5%" align="left">
                {setSelectAllTable && !healthMode && (
                  <Checkbox
                    data-cy="asin_checkbox_all"
                    indeterminate={someSelected && !selectAll}
                    checked={selectAll}
                    onChange={setSelectAllTable}
                  />
                )}
                {!selectAll && ' '}
              </TableCell>
              <TableCell width={allAsinsMode ? '40%' : '50%'}>Product</TableCell>
              {allAsinsMode && <TableCell width="10%">Category</TableCell>}
              <TableCell width="10%">Variants</TableCell>
              <TableCell width="7%" align="right">
                Price
              </TableCell>
              <TableCell width="8%" align="right">
                Score
              </TableCell>
              {!healthMode && (
                <TableCell width="8%" align="center">
                  Offers
                </TableCell>
              )}
              {healthMode && (
                <TableCell width="12%" align="right">
                  <Tooltip title="7-Day Estimated Sales Taken">
                    Lost Sales
                    <InfoIcon sx={{ ml: '4px', width: '13px', height: '13px' }} />
                  </Tooltip>
                </TableCell>
              )}
              <TableCell width="10%" align={allAsinsMode ? 'right' : 'left'}>
                {healthMode && showTier && (
                  <Box>Tier</Box>
                )}
                {!(healthMode && showTier) && (
                  <>
                    {allAsinsMode ? <Box>Auth Win&nbsp;Rate</Box> : 'Added'}
                  </>
                )}
              </TableCell>
              <TableCell width="2%"> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {asins.slice(0, limit).map((asin) => (
              <AsinRow
                simpleTier={asin.tier === 'twiceDaily' || asin.tier === 'daily'}
                healthMode={healthMode}
                showTier={showTier}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                key={`${asin.id}-${asin.parentAsin}-${asin.parent}-${asin.asin}`}
                asin={asin}
                changeChecked={changeChecked}
                showParent={showParent}
                onExpand={handleExpand}
                onEdit={() => {
                  if (setEditAsin) {
                    setEditAsin(asin);
                  }
                }}
                onDelete={() => {
                  if (setDeleteConfirmationAsinId) {
                    setDeleteConfirmationAsinId(asin.parent ? asin.parentAsin : asin.id);
                  }
                }}
                setSellerProfile={setSellerProfile}
                readOnly={readOnly}
                filterSeller={filterSeller}
                showWinRate={showWinRate}
                onAuthorizedChanged={onAuthorizedChanged}
                allAsinsMode={allAsinsMode}
                onMovedItem={onMovedItem}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </InfiniteScroll>
  );
};

AsinTable.propTypes = {
  showParent: PropTypes.bool,
  asins: PropTypes.arrayOf(PropTypes.object).isRequired,
  setEditAsin: PropTypes.func,
  setDeleteConfirmationAsinId: PropTypes.func,
  changeChecked: PropTypes.func,
  onExpand: PropTypes.func,
  selectAll: PropTypes.bool,
  setSelectAll: PropTypes.func,
  setSellerProfile: PropTypes.func,
  readOnly: PropTypes.bool,
  filterSeller: PropTypes.string,
  showWinRate: PropTypes.bool,
  onAuthorizedChanged: PropTypes.func,
  setSelectAllTable: PropTypes.func,
  someSelected: PropTypes.bool,
  allAsinsMode: PropTypes.bool,
  healthMode: PropTypes.bool,
  showTier: PropTypes.bool,
};

AsinTable.defaultProps = {
  showParent: true,
  selectAll: false,
  setSelectAll: null,
  setSellerProfile: null,
  readOnly: false,
  filterSeller: null,
  showWinRate: false,
  setEditAsin: null,
  setDeleteConfirmationAsinId: null,
  changeChecked: null,
  onExpand: null,
  onAuthorizedChanged: null,
  setSelectAllTable: null,
  someSelected: false,
  allAsinsMode: false,
  healthMode: false,
  showTier: false,
};

export default AsinTable;
