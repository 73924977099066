import React, { useContext, useEffect, useState } from 'react';
import { ToastDataContext } from 'contexts';
import { useLazyQuery, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import GasGauge from './GasGauge';

const ProductHealthWidget = ({ asinCount }) => {
  const { setToast } = useContext(ToastDataContext);
  const [loading, setLoading] = useState(true);
  const [score, setScore] = useState(null);
  const history = useHistory();

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      setLoading(false);
      setScore(dataToProcess.getAsinHealthIndex)
    }
  }

  const HEALTH_QUERY = gql`
    query GetAsinHealthIndex {
      getAsinHealthIndex
    }
  `;
  const [fetchData] = useLazyQuery(HEALTH_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    fetchData();
  }, [asinCount]);

  const handleClickTitle = () => {
    history.push('/asins');
  }

  return (
    <Box sx={{ cursor: 'pointer' }} onClick={handleClickTitle}>
      <GasGauge
        loading={loading}
        title="ASIN Health Index"
        value={score}
        min={-100}
        max={100}
      />
    </Box>
  );
};

export default ProductHealthWidget;
