import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, Box, Button, useTheme } from '@mui/material';
import { AuthDataContext } from 'contexts';
import userService from 'services/user-service';
import accountService from 'services/account-service';
import AccountChooserDialog from 'pages/Login/AccountChooserDialog';
import { LOCAL_STORAGE_ACCOUNTID_SUFFIX, LOCAL_STORAGE_AMAZON_REGION_SUFFIX, USA_REGION } from 'utils/constants';
import { createLocalStorageKey } from 'utils/misc';
import { Auth } from 'aws-amplify';

const FedLogin = () => {
  const [userAccounts, setUserAccounts] = useState(null);
  const { setUser, tenant, setAccount } = useContext(AuthDataContext);
  const [formError, setFormError] = useState(null);
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const loginWithAccount = async (userAccount, redirectUrl, passedRegionId) => {
    const accountKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: userAccount.userId, suffix: LOCAL_STORAGE_ACCOUNTID_SUFFIX });
    localStorage.setItem(accountKey, userAccount.accountId);
    const regionKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: userAccount.userId, suffix: LOCAL_STORAGE_AMAZON_REGION_SUFFIX, accountId: userAccount.accountId });
    const r = passedRegionId ?? localStorage.getItem(regionKey);
    if (!r) {
        localStorage.setItem(regionKey, USA_REGION);
    } else if (passedRegionId) {
      localStorage.setItem(regionKey, passedRegionId);
    }
    const userProfile = await userService.getUser();
    await userService.updateUserLoggedIn();
    setUser({ profile: userProfile });
    const acct = await accountService.getAccountById(userAccount.accountId);
    setAccount(acct);

    if (redirectUrl) {
      history.replace(redirectUrl);
    } else if (userAccount.tenantPath !== tenant.tenant) {
      window.location.assign(`/${userAccount.tenantPath}/dashboard`);
    } else {
      history.replace('/dashboard');
    }
    setUserAccounts(null);
  }

  const handleAccountChoice = async (accounts) => {
    setUserAccounts(accounts);
  }
  
  const handleCancelChoice = () => {
    setUserAccounts(null);
  }

  useEffect(async () => {
    try {

      const urlSafeDecode = (hex) => {
        const matchArr = hex.match(/.{2}/g) || [];
        return matchArr.map(char => String.fromCharCode(parseInt(char, 16))).join('');
      }

      let url = null;
      const stateArray = query.get('state')?.split("-");
      if (stateArray?.length > 1) {
        url = urlSafeDecode(stateArray[1]);
      }
      const accounts = await userService.getAvailableAccounts({ tenant: tenant.tenant });
      // This is kind of a hack to update the JWT to include the new Stripe customer ID that has been added
      // to the Cognito account, as this gets sent down in the JWT and is used by the back-end for Stripe calls
      const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.refreshToken, async () => {
        if (accounts.length === 0) {
          setFormError('User is not enabled in any accounts, please contact support to be added to an account.');
        } else if (accounts.length === 1) {
          await loginWithAccount(accounts[0]);
        } else {
          let passedAccountId = null;
          let passedRegionId = null;
          if (url) {
            const aidQuery = new URLSearchParams(url);
            passedAccountId = aidQuery.get('aid') ? decodeURIComponent(aidQuery.get('aid')) : null;
            passedRegionId = aidQuery.get('rid') ? decodeURIComponent(aidQuery.get('rid')) : null;
          }
          let found = null;
          for (let i = 0; i < accounts.length; i += 1) {
            const account = accounts[i];
            if (account.accountId === parseInt(passedAccountId, 10)) {
              found = account;
            }
          }
          if (found) {
            await loginWithAccount(found, url, passedRegionId);
          } else {
            await handleAccountChoice(accounts);
          }
        } 
      });
    } catch (err) {
      setFormError('User does not exist in system, please contact support.')
      console.log(err);
    }
  }, []);

  return (
    <Box sx={{ mt: 8, textAlign: 'center' }}>
      {!formError && (<Typography>Logging In...</Typography>)}
      {formError && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Typography sx={{ ...theme.custom.login.error }}>{formError}</Typography>
          <Box>
            <Button
              onClick={() => history.push('/login')}
            >
              Go Back
            </Button>
          </Box>
        </Box>
      )}
      {userAccounts && (
        <AccountChooserDialog
          userAccounts={userAccounts}
          onCancel={() => handleCancelChoice()}
          onChoose={loginWithAccount}
        />
      )}
    </Box>
  );
};

export default FedLogin;
