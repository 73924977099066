import React, { useState, useContext } from 'react';
import { Loading } from 'components';
import { gql, useQuery } from '@apollo/client';
import { Grid, Box, useTheme } from '@mui/material';
import { AuthDataContext, ToastDataContext, RegionDataContext } from 'contexts';

const HistoryAboutItem = ({ title, info }) => (
  <>
    <Box sx={{ fontSize: '14px', fontWeight: '600' }}>{title}</Box>
    <Box sx={{ fontSize: '14px', fontWeight: '400' }}>{info}</Box>
  </>
);


const OfferScore = ({ offer }) => {
  const [ruleTypes, setRuleTypes] = useState(null);
  const { region } = useContext(AuthDataContext);
  const theme = useTheme();
  const { setToast } = useContext(ToastDataContext);
  const { regionCurrency } = useContext(RegionDataContext);

  const GET_TYPES_QUERY = gql`
    query GetTypes {
      getRuleTypes {
        id
        description
        prompt
        value
      }
    }
  `;

  const processData = (dataToProcess) => {
    setRuleTypes(dataToProcess.getRuleTypes);
  };

  const { loading } = useQuery(GET_TYPES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
    onCompleted: processData,
  });

  const ruleTypesIds = {
    PRICE_LOW: 1,
    THIRTY_DAYS: 2,
    TWELVE_MONTHS: 3,
    LIFETIME_REVIEW: 4,
    SHIPPING_PRICE: 5,
    SHIPPING_DURATION: 6,
    DISTANCE: 7,
    FOREIGN: 8,
    ACTIONED: 9,
    PRICE_HIGH: 10,
  };

  const getRangeForAsin = (ruleType) => {
    let rule = null;
    for (let i = 0; i < ruleTypes.length; i += 1) {
      if (ruleTypes[i].id === ruleType) {
        rule = ruleTypes[i];
        break;
      }
    }
    if (rule?.id === ruleTypesIds.PRICE_LOW) {
      let low = offer.mapPrice - (offer.mapPrice * offer[`rule${rule.id}threshold`]) / 100.0;
      low = Math.round((Math.max(low, 0) + Number.EPSILON) * 100) / 100
      return { low, high: 0 };
    }
    if (rule?.id === ruleTypesIds.PRICE_HIGH) {
      let high = offer.mapPrice + (offer.mapPrice * offer[`rule${rule.id}threshold`]) / 100.0;
      high = (Math.round(high + Number.EPSILON) * 100) / 100
      return { low: 0, high };
    }
    if (rule?.id === ruleTypesIds.SHIPPING_PRICE) {
      const low = 0;
      const high = offer[`rule${rule.id}threshold`];
      return { low: Math.max(low, 0), high };
    }
    if (rule?.id === ruleTypesIds.SHIPPING_DURATION) {
      const low = 0;
      const high = offer[`rule${rule.id}threshold`];
      return { low: Math.max(low, 0), high };
    }
    if (rule?.id === ruleTypesIds.THIRTY_DAYS) {
      const low = 0;
      const high = offer[`rule${rule.id}threshold`];
      return { low: Math.max(low, 0), high };
    }
    if (rule?.id === ruleTypesIds.TWELVE_MONTHS) {
      const low = 0;
      const high = offer[`rule${rule.id}threshold`];
      return { low: Math.max(low, 0), high };
    }
    if (rule?.id === ruleTypesIds.LIFETIME_REVIEW) {
      const low = 0;
      const high = offer[`rule${rule.id}threshold`];
      return { low: Math.max(low, 0), high };
    }
    if (rule?.id === ruleTypesIds.DISTANCE) {
      const low = 0;
      const high = offer[`rule${rule.id}threshold`];
      return { low: Math.max(low, 0), high };
    }
    return {
      low: 0,
      high: 0,
    };
  };

  const getValueString = (ruleType) => {
    const range = getRangeForAsin(ruleType);
    let badValue = false;
    if (ruleType === ruleTypesIds.PRICE_LOW) {
      if (offer[`rule${ruleType}actual`] < range.low) {
        if (range?.low !== 0) {
          badValue = true;
        }
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer[`rule${ruleType}actual`] !== null || Number.isNaN(offer[`rule${ruleType}actual`])
            ? Intl.NumberFormat(region, { style: 'currency', currency: regionCurrency }).format(
              offer[`rule${ruleType}actual`]?.toFixed(2),
            )
            : 'N/A'}
        </Box>
      );
    }
    if (ruleType === ruleTypesIds.PRICE_HIGH) {
      if (offer[`rule${ruleType}actual`] > range.high) {
        if (range?.high !== 0) {
          badValue = true;
        }
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer[`rule${ruleType}actual`] !== null || Number.isNaN(offer[`rule${ruleType}actual`])
            ? Intl.NumberFormat(region, { style: 'currency', currency: regionCurrency }).format(
              offer[`rule${ruleType}actual`]?.toFixed(2),
            )
            : 'N/A'}
        </Box>
      );
    }
    if (ruleType === ruleTypesIds.SHIPPING_PRICE) {
      if (offer[`rule${ruleType}actual`] > range?.high) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer[`rule${ruleType}actual`] !== null
            ? Intl.NumberFormat(region, { style: 'currency', currency: regionCurrency }).format(
              offer[`rule${ruleType}actual`]?.toFixed(2),
            )
            : 'N/A'}
        </Box>
      );
    }
    if (ruleType === ruleTypesIds.SHIPPING_DURATION) {
      if (offer[`rule${ruleType}actual`] >= range?.high) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer[`rule${ruleType}actual`] !== null ? offer[`rule${ruleType}actual`]?.toFixed(0) : 'N/A'}
        </Box>
      );
    }
    if (ruleType === ruleTypesIds.THIRTY_DAYS) {
      if (offer[`rule${ruleType}actual`] > range?.high) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer[`rule${ruleType}actual`] !== null ? `${offer[`rule${ruleType}actual`]?.toFixed(0)}%` : '0'}
        </Box>
      );
    }
    if (ruleType === ruleTypesIds.TWELVE_MONTHS) {
      if (offer[`rule${ruleType}actual`] > range?.high) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer[`rule${ruleType}actual`] !== null
            ? `${offer[`rule${ruleType}actual`]?.toFixed(0)}%`
            : 'N/A'}
        </Box>
      );
    }
    if (ruleType === ruleTypesIds.LIFETIME_REVIEW) {
      if (offer[`rule${ruleType}actual`] < range.high) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer[`rule${ruleType}actual`]?.toFixed(0)}
        </Box>
      );
    }
    if (ruleType === ruleTypesIds.DISTANCE && offer[`rule${ruleType}actual`]) {
      if (offer[`rule${ruleType}actual`] < range.high) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer[`rule${ruleType}actual`] ? `${offer[`rule${ruleType}actual`]} miles` : `N/A`}
        </Box>
      );
    }
    if (ruleType === ruleTypesIds.FOREIGN) {
      if (offer[`rule${ruleType}actual`] !== offer[`rule${ruleType}threshold`]) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer[`rule${ruleType}actual`]}
        </Box>
      );
    }
    if (ruleType === ruleTypesIds.ACTIONED) {
      if (offer[`rule${ruleType}actual`] === 1) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer[`rule${ruleType}actual`] ? 'Yes' : 'No'}
        </Box>
      );
    }
    return null;
  };

  const getRangeForAsinString = (ruleType) => {
    const range = getRangeForAsin(ruleType);
    if (ruleType === ruleTypesIds.PRICE_LOW) {
      if (range?.low === 0) {
        return '';
      }
      return `(>${Intl.NumberFormat(region, {
        style: 'currency',
        currency: regionCurrency,
      }).format(range.low?.toFixed(2))})`;
    }
    if (ruleType === ruleTypesIds.PRICE_HIGH) {
      if (range?.high === 0) {
        return '';
      }
      return `(<${Intl.NumberFormat(region, {
        style: 'currency',
        currency: regionCurrency,
      }).format(range.high?.toFixed(2))})`;
    }
    if (ruleType === ruleTypesIds.SHIPPING_PRICE) {
      return `(<${Intl.NumberFormat(region, {
        style: 'currency',
        currency: regionCurrency,
      }).format(range?.high?.toFixed(2))})`;
    }
    if (ruleType === ruleTypesIds.SHIPPING_DURATION) {
      return `(<${range?.high?.toFixed(0)} days)`;
    }
    if (ruleType === ruleTypesIds.THIRTY_DAYS || ruleType === ruleTypesIds.TWELVE_MONTHS) {
      return `(<${range?.high?.toFixed(0)}%)`;
    }
    if (ruleType === ruleTypesIds.LIFETIME_REVIEW) {
      return `(>${range?.high?.toFixed(0)})`;
    }
    if (ruleType === ruleTypesIds.DISTANCE) {
      return `(>${range?.high?.toFixed(0)} miles)`;
    }
    if (ruleType === ruleTypesIds.FOREIGN) {
      return `(${offer[`rule${ruleType}threshold`]})`;
    }
    return null;
  };

  const noScoringAvailable = () => {
    let found = 0;
    for (let i = 0; i < ruleTypes.length; i += 1) {
      if (offer[`rule${ruleTypes[i].id}threshold`] !== null) {
        found = 1;
      }
    }
    return !found;
  }

  return (
    <>
      {loading && <Box sx={{ width: '500px' }}><Loading /></Box>}
      {!loading && offer && ruleTypes && (
        <Box sx={{ m: 1, p: 3, bgcolor: 'greys.white', borderRadius: theme.largeBorderRadius, width: '500px' }}>
          <Grid container item direction="row" spacing={2}>
            {ruleTypes.map(r => {
              if (offer[`rule${r.id}threshold`] === null) {
                return null;
              }
              return (
                <Grid key={r.id} item xs={6}>
                  <HistoryAboutItem
                    title={r.description}
                    info={
                      <Box sx={{ display: 'flex' }}>
                        {getValueString(r.id, offer)}&nbsp;{getRangeForAsinString(r.id)}
                      </Box>
                    }
                  />
                </Grid>
              )
            })}
            {noScoringAvailable() && (
              <Grid item xs={12} sx={{ fontSize: '14px', color: 'greys.darkGrey' }}>
                No recent scoring information available
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default OfferScore;
