import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableBody,
  Typography,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  Tooltip,
  styled,
  tableCellClasses,
  Box,
  useTheme,
} from '@mui/material';
import { Loading } from 'components';
import { ReactComponent as OffersIcon } from 'assets/offers-icon.svg';
import { formatDateTime } from 'utils/dates';
import InfiniteScroll from 'react-infinite-scroller';
import { ToastDataContext, AuthDataContext } from 'contexts';
import { NavLink } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import DetachedSellersDrawer from 'pages/Sellers/DetachedSellersDrawer';
import OfferRow from './OfferRow';

const StyledTableHeaderCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F7F7FA',
  },
}));

const OffersPanel = ({
  showOffers,
  asin,
  drawer,
  setSellerProfile,
  filterSeller,
  showWinRate,
  onAuthorizedChanged,
  simpleTier,
}) => {
  const [loadingOffers, setLoadingOffers] = useState(true);
  const [offers, setOffers] = useState([]);
  const [detachedSellers, setDetachedSellers] = useState([]);
  const [showAsin, setShowAsin] = useState(null);
  const [missingWinRate, setMissingWinRate] = useState(false);
  const scrollPageLength = drawer ? 10 : 4;
  const [limit, setLimit] = useState(scrollPageLength);
  const [updatedDate, setUpdatedDate] = useState('');
  const { setToast } = useContext(ToastDataContext);
  const { account } = useContext(AuthDataContext);
  const theme = useTheme();

  const updatedDateForItems = (items) => {
    let index;
    let mostRecentDate = null;
    for (index = 0; index < items.length; index += 1) {
      const item = items[index];
      if (mostRecentDate === null) {
        if (item.harvestDate) {
          mostRecentDate = new Date(item.harvestDate);
        }
      } else if (item.harvestDate) {
        const currentDate = new Date(item.harvestDate);
        if (mostRecentDate < currentDate) {
          mostRecentDate = currentDate;
        }
      }
    }
    if (mostRecentDate !== null) {
      return formatDateTime(mostRecentDate);
    }
    return 'N/A';
  };

  const processOffersData = (dataToProcess) => {
    if (dataToProcess) {
      let result = dataToProcess.getOffersForAsin.concat();
      result = result.filter((a) => a.isNew);
      if (filterSeller) {
        result = result.filter((a) => a.sellerId !== filterSeller);
      }
      result.sort((a, b) => {
        const sortResult = parseFloat(a.price) - parseFloat(b.price);
        if (sortResult === 0) {
          return a.sellerName.localeCompare(b.sellerName);
        }
        return sortResult;
    });
      const sum = result.reduce((a, b) => a + b.buyBoxWinPercentage, 0);
      setMissingWinRate(sum < 100);
      setUpdatedDate(updatedDateForItems(result));
      setOffers(result);
      setLoadingOffers(false);
    }
  };

  const GET_OFFERS_FOR_ASIN_QUERY = gql`
    query GetOffersForAsin($asin: String!) {
      getOffersForAsin(asin: $asin) {
        id
        offerId
        sellerId
        sellerName
        sellerLink
        sellerAuthorized
        harvestDate
        categoryId
        asin
        globalAuth
        actionHistory {
          actionTaken
          description
          createdDate
        }
        isNew
        isBuyBoxWinner
        fulfilledByAmazon
        buyBoxWinPercentage
        hasMultipleOffers
        name
        price
        stockEstimation
        currentScore {
          score
          harvestDate
        }
        mapPrice
        rule1threshold
        rule1actual
        rule2threshold
        rule2actual
        rule3threshold
        rule3actual
        rule4threshold
        rule4actual
        rule5threshold
        rule5actual
        rule6threshold
        rule6actual
        rule7threshold
        rule7actual
        rule8threshold
        rule8actual
        rule9threshold
        rule9actual
        rule10threshold
        rule10actual
      }
    }
  `;

  const { data } = useQuery(GET_OFFERS_FOR_ASIN_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    skip: !asin,
    variables: {
      asin: asin.asin,
    },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const GET_DETACHED_SELLERS_QUERY = gql`
    query GetDetachedSellers($asin: String!) {
      getDetachedSellersForAsin(asin: $asin) {
        actionsTaken
        harvestDate
        name
        sellerId
      }
    }
  `;

  // Using lazy query here, because of what looks to be a bug in Apollo, where the query keeps getting re-executed
  // with the cache-and-network setting even though query variables aren't changing, and using a lazy query
  // works around this bug
  const { data: detachedData } = useQuery(GET_DETACHED_SELLERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    skip: !asin,
    variables: {
      asin: asin.asin,
    },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (data) {
      processOffersData(data);
    }
  }, [data]);

  useEffect(() => {
    if (detachedData) {
      const sellers = detachedData.getDetachedSellersForAsin || [];
      const sortedSellers = sellers
        .concat()
        .sort((a, b) => new Date(b.harvestDate) - new Date(a.harvestDate));
      setDetachedSellers(sortedSellers);
    }
  }, [detachedData]);

  const loadMore = async () => {
    if (limit < offers.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > offers.length) {
        newLimit = offers.length;
      }
      setLimit(newLimit);
    }
  };

  const maxHeight = drawer ? 'auto' : `${Math.min(226, 42 + asin.offersCount * 47)}px`;

  return (
    <Box
      sx={{
        borderTop: `1px solid ${theme.palette.greys.lightGrey}`,
        width: '100%',
        ml: showOffers ? '0px' : '-0.5px',
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <Box
        sx={{
          p: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography
                data-cy="offer_drawer_asin_title"
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '24px',
                }}
              >
                ASIN #{asin?.asin}
              </Typography>
            </Box>
            <Box>
              {!loadingOffers && (
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: 'greys.silver',
                  }}
                >
                  Updated: {updatedDate}
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '3px',
              }}
            >
              <Box>
                <OffersIcon fill={theme.palette.greys.grey} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: 'greys.silver',
                  }}
                >
                  {asin?.offersCount} {asin?.offersCount > 1 ? 'offers' : 'offer'}
                </Typography>
              </Box>
            </Box>
            {!simpleTier && detachedSellers.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                {missingWinRate && (
                  <Box
                    sx={{
                      fontSize: '12px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: 'greys.grey',
                      mr: '4px',
                    }}
                  >
                    Remaining Win Rate percentage is from some of the
                  </Box>
                )}
                <Box
                  sx={{
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#0071DA',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowAsin(asin.asin)}
                >
                  {`${detachedSellers.length} detached ${
                    detachedSellers.length === 1 ? 'seller' : 'sellers'
                  }`}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {(loadingOffers || !showOffers) && (
          <Box
            sx={{
              minHeight: maxHeight,
              maxHeight,
            }}
          >
            {loadingOffers && <Loading />}
          </Box>
        )}
        {!loadingOffers && showOffers && offers && (
          <TableContainer
            sx={{
              minHeight: maxHeight,
              maxHeight,
              overflowY: 'initial',
            }}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={limit < offers.length}
              loader={limit < offers.length && <Loading key={0} />}
              useWindow={false}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell data-cy="offer_row_seller_name">
                      Seller Name
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell width="8%" align="right">
                      Price
                    </StyledTableHeaderCell>
                    {!simpleTier && (
                      <StyledTableHeaderCell width="8%" align="right">
                        Score
                      </StyledTableHeaderCell>
                    )}
                    {showWinRate && (
                      <StyledTableHeaderCell width="15%" align="right">
                        Win Rate
                      </StyledTableHeaderCell>
                    )}
                    {!simpleTier && (
                      <StyledTableHeaderCell width="8%" align="right">
                        {account.fetchInventory && <Box>Inventory</Box>}
                        {!account.fetchInventory && (
                          <Tooltip
                            title="Contact sales to get inventory"
                            arrow
                            placement="bottom"
                            sx={{ cursor: 'default' }}
                          >
                            <NavLink to="/settings/plan" style={{ textDecoration: 'underline' }}>
                              Inventory
                            </NavLink>
                          </Tooltip>
                        )}
                      </StyledTableHeaderCell>
                    )}
                    {!simpleTier && <StyledTableHeaderCell width="2%"> </StyledTableHeaderCell>}
                  </TableRow>
                </TableHead>
                <TableBody data-cy="asin_offers">
                  {!loadingOffers && showOffers && (
                    <>
                      {offers.slice(0, limit).map((offer) => (
                        <OfferRow
                          showWinRate={showWinRate}
                          key={offer.offerId}
                          offer={offer}
                          showInventory={account.fetchInventory}
                          setSellerProfile={setSellerProfile}
                          onAuthorizedChanged={onAuthorizedChanged}
                          simpleTier={simpleTier}
                        />
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        )}
      </Box>
      <DetachedSellersDrawer asin={showAsin} open={!!showAsin} onClose={() => setShowAsin(null)} />
    </Box>
  );
};

OffersPanel.propTypes = {
  asin: PropTypes.oneOfType([PropTypes.object]).isRequired,
  showOffers: PropTypes.bool,
  drawer: PropTypes.bool,
  setSellerProfile: PropTypes.func,
  filterSeller: PropTypes.string,
  showWinRate: PropTypes.bool,
  onAuthorizedChanged: PropTypes.func,
};

OffersPanel.defaultProps = {
  showOffers: false,
  drawer: false,
  setSellerProfile: null,
  filterSeller: null,
  showWinRate: false,
  onAuthorizedChanged: null,
};

export default OffersPanel;
