/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  TextField,
  InputAdornment,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  IconButton,
  Box,
  useTheme,
} from '@mui/material';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import InfiniteScroll from 'react-infinite-scroller';
import { Loading, Scrollable } from 'components';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ToastDataContext } from 'contexts';
import { foundKeywords } from 'utils/strings';
import { useQuery, gql, useMutation } from '@apollo/client';
import { ReactComponent as EmptyPage } from 'assets/empty-page.svg';
import dayjs from 'dayjs';
import EnforcementItem from './EnforcementItem';
import EnforcementDetail from './EnforcementDetail';

const EnforcementsList = () => {
  const [enforcements, setEnforcements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actionTypes, setActionTypes] = useState([]);
  const [filtered, setFiltered] = useState({});
  const [selectedEnforcementId, setSelectedEnforcementId] = useState(null);
  const scrollPageLength = 10;
  const [limit, setLimit] = useState(scrollPageLength);
  const [search, setSearch] = useState('');
  const theme = useTheme();
  const { setToast } = useContext(ToastDataContext);

  const GET_ACTIONS_QUERY = gql`
    query GetActions {
      getActions {
        id
        description
      }
      getEnforcementQueueItems {
        id
        createdDate
        followUpDate
        offers {
          id
          sellerId
          asin
          imageUrl
          offerId
          sellerName
          sellerAuthorized
          name
          price
          mapPrice
          categoryId
          globalAuth
          harvestDate
          regionId
          attached
          category {
            id
            name
          }
          currentScore {
            score
            harvestDate
          }
        }
        actions {
          id
          letterType
          actionTaken
          status
          statusExtra
          tracking
          description
          createdDate
          updatedDate
          templateName
          userName
          userEmail
          violationJson
          fields {
            id
            idString
            customFieldId
            customValueId
            name
            description
            type
            area
            stringOptions
            valueInt
            valueString
            valueDate
            valueStrings
            valueAddress {
              addressLine1
              addressLine2
              city
              state
              zip
            }
            multiple
            timestamp
            extraTextLabel
            extraText
            userEmail
            userFirstName
            userLastName
            updatedDate
            createdDate
            s3filename
          }
        }
      }
    }
  `;

  const { data, refetch } = useQuery(GET_ACTIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const UPDATE_ENFORCEMENT_ITEM_MUTATION = gql`
    mutation UpdateEnforcementFollowUpDate($id: ID!, $followUpDate: DateTime) {
      updateEnforcementFollowUpDate(id: $id, followUpDate: $followUpDate)
    }
  `;

  const [updateFollowUpDate] = useMutation(UPDATE_ENFORCEMENT_ITEM_MUTATION, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const onUpdateFollowUpDate = ({enforcement, followUpDate}) => {
    try {
      updateFollowUpDate({
        variables: {
          id: enforcement.id,
          followUpDate,
        },
        update(cache) {
          cache.modify({
            id: cache.identify(enforcement),
            fields: {
              followUpDate() {
                return followUpDate;
              },
            },
          });
        },
      });
    } catch (e) {
      setToast({ type: 'error', message: e.message });
    }
  }

  const processData = (dataToProcess) => {
    setActionTypes(dataToProcess.getActions);

    const sent = dataToProcess.getEnforcementQueueItems.filter(
      (e) => e.actions.length > 0 && e.offers.length > 0,
    );
    const mapped = sent.map((e) => {
      const offerStrings = e.offers.map((o) => [
        o.asin,
        o.sellerName,
        o.sellerId,
        o.productName,
        o.category?.name ?? '',
      ]);
      const notesStrings = e.actions?.map((a) => [
        a.notes ?? ''
      ]);
      const allStrings = offerStrings.concat(notesStrings);
      return {
        ...e,
        objString: JSON.stringify(allStrings),
      };
    });
    mapped.sort((a,b) => {
      if (a.followUpDate && !b.followUpDate) {
        return -1;
      } if (!a.followUpDate && b.followUpDate) {
        return 1;
      } if (a.followUpDate && b.followUpDate) {
        const day1 = dayjs(a.followUpDate);
        const day2 = dayjs(b.followUpDate);
        return day1.diff(day2);
      }
      const day1 = dayjs(a.createdDate);
      const day2 = dayjs(b.createdDate);
      return day2.diff(day1);
    });
    setEnforcements(mapped);
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      processData(data);
    }
  }, [data]);


  const selectedEnforcement = selectedEnforcementId !== null && filtered.length > 0 ? filtered.find(e => e.id === selectedEnforcementId) : null;

  const loadMore = () => {
    if (limit < filtered.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > filtered.length) {
        newLimit = filtered.length;
      }
      setLimit(newLimit);
    }
  };

  const onSearch = (keyword) => {
    setSearch(keyword);
    const newFiltered = enforcements.filter((e) => foundKeywords(e.objString, keyword));
    setFiltered(newFiltered);
    if (newFiltered.length > 0) {
      if (selectedEnforcementId === null || newFiltered.find(e => e.id === selectedEnforcementId) === null) {
        setSelectedEnforcementId(newFiltered[0].id);
      } 
    } else {
      setSelectedEnforcementId(null);
    }
  };

  useEffect(() => {
    if (enforcements.length > 0) {
      onSearch(search);
    }
  }, []);

  useEffect(() => {
    onSearch(search);
  }, [enforcements]);

  return (
    <Scrollable transparent transparentPadding={2} sx={{ height: '100%' }}>
      {loading && <Loading />}
      {!loading && enforcements.length === 0 && (
        <Box sx={{ textAlign: 'center' }}>
          <EmptyPage />
          <Box component="p" sx={{ color: 'greys.grey' }} data-cy="no_enforcements_text">
            No Pending or Sent Actions
          </Box>
          <Box component="p" sx={{ color: 'greys.grey' }}>
            To queue low-scoring sellers or create actions on low-scoring sellers, please go to
            the Dashboard.
          </Box>
        </Box>
      )}
      {!loading && enforcements.length > 0 && (
        <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
          <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <TextField
              onChange={(event) => onSearch(event.target.value)}
              multiline
              placeholder="Search"
              data-cy="enforcement_search"
              sx={{ pr: 2, pb: 2 }}
              value={search}
              InputProps={{
                sx: { minHeight: '40px', height: '40px', pl: 1 },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fill={theme.palette.greys.silver} />
                  </InputAdornment>
                ),
                endAdornment: search ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        onSearch('');
                      }}
                      size="large"
                    >
                      <ClearIcon fill={theme.palette.greys.silver} data-cy="enforcement_search_clear" />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  ''
                ),
              }}
              fullWidth
            />
            <Box sx={{ overflow: 'scroll' }}>
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore
                loader={limit < filtered.length && <Loading key={0} />}
                useWindow={false}
              >
                <TableContainer>
                  <Table>
                    <TableBody>
                      {filtered &&
                        filtered.length > 0 &&
                        filtered.slice(0, limit).map((e) => (
                          <TableRow key={e.id} sx={{ borderRight: 'none' }}>
                            <TableCell
                              sx={{
                                margin: 0,
                                cursor: 'pointer',
                                bgcolor: selectedEnforcement?.id === e.id ? 'greys.white' : 'transparent',
                              }}
                            >
                              <EnforcementItem
                                onRefetch={refetch}
                                enforcement={e}
                                selectedEnforcement={selectedEnforcement?.id}
                                setSelectedEnforcement={(se) =>
                                  setSelectedEnforcementId(se.id)
                                }
                                actionTypes={actionTypes}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {filtered.length === 0 && (
                  <Box sx={{ ml: '4px', fontSize: '14px' }} data-cy="no_results">No Results</Box>
                )}
              </InfiniteScroll>
            </Box>
          </Box>
          <Box sx={{ width: '60%', display: 'flex', flexDirection: 'column', height: '100%' }}>
            {selectedEnforcement && (
              <EnforcementDetail
                enforcement={selectedEnforcement}
                actionTypes={actionTypes}
                onActionTaken={refetch}
                onRefetch={refetch}
                onUpdateFollowUpDate={onUpdateFollowUpDate}
              />
            )}
          </Box>
        </Box>
      )}
    </Scrollable>
  );
};

export default EnforcementsList;
