import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  tableRowClasses,
  styled,
} from '@mui/material';
import { Loading, DateRangeDropdown } from 'components';
import { ToastDataContext, RegionDataContext, AuthDataContext } from 'contexts';
import { gql, useLazyQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import LostBBSellerDrawer from 'pages/Analytics/LostSales/LostBBTable/LostBBSellerDrawer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import WidgetHeader from './WidgetHeader';
import Widget from './Widget';

dayjs.extend(utc);
dayjs.extend(timezone);

const StyledTableCell = styled(TableCell)(({ sx, theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.greys.silver,
    backgroundColor: theme.palette.greys.white,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '22px',
    textTransform: 'uppercase',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '13px',
    fontWeight: '400',
    borderLeft: 'none',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.root}`]: {
    ...sx,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}}`,
    '&:last-child': {
      paddingRight: theme.spacing(3),
    },
    '&:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.greys.backgroundGrey,
    },
    border: 'none',
  },
}));

const StyledTableHeaderRow = styled(TableRow)(() => ({
  [`&.${tableRowClasses.root}`]: {
    borderLeft: 'none',
    borderRight: 'none',
  },
}));

const LostSalesWidget = ({ asinCount }) => {
  const { setToast } = useContext(ToastDataContext);
  const { region } = useContext(AuthDataContext);
  const { regionCurrency } = useContext(RegionDataContext);
  const [loading, setLoading] = useState(true);
  const [totalLostSales, setTotalLostSales] = useState(null);
  const [showSellerDrawer, setShowSellerDrawer] = useState(null);
  const [topData, setTopData] = useState([]);

  const dateRanges = (() => {
    const date = new Date();
    return [
      {
        label: 'Yesterday',
        startDate: dayjs(date).add(-1, 'day').startOf('day').toDate(),
        endDate: dayjs(date).add(-1, 'day').endOf('day').toDate(),
      },
      {
        label: 'Last Week',
        startDate: dayjs(date).add(-1, 'week').startOf('week').toDate(),
        endDate: dayjs(date).add(-1, 'week').endOf('week').toDate(),
      },
      {
        label: 'This Month',
        startDate: dayjs(date).startOf('month').toDate(),
        endDate: dayjs(date).endOf('month').toDate(),
      },
      {
        label: 'Last Month',
        startDate: dayjs(date).add(-1, 'month').startOf('month').toDate(),
        endDate: dayjs(date).add(-1, 'month').endOf('month').toDate(),
      },
      {
        label: 'Last 7 Days',
        startDate: dayjs(date).add(-6, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
      {
        label: 'Last 30 Days',
        startDate: dayjs(date).add(-29, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
      {
        label: 'Last 90 Days',
        startDate: dayjs(date).add(-89, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
      {
        label: 'Last 180 Days',
        startDate: dayjs(date).add(-179, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
    ];
  })();

  const [dateRange, setDateRange] = useState(dateRanges[4]);

  const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateStart =  dayjs(dayjs(dateRange.startDate).tz(timeZoneName).format('YYYY/MM/DD')).tz('UTC').startOf('day').toISOString();
  const dateEnd = dayjs(dayjs(dateRange.endDate).tz(timeZoneName).format('YYYY/MM/DD')).tz('UTC').endOf('day').toISOString();

  const GET_TOP_LOST_BUYBOX_QUERY = gql`
    query GetTopLostBuyBox($categoryId: Int, $dateStart: DateTime, $dateEnd: DateTime) {
      getTopLostBuyBox(categoryId: $categoryId, dateStart: $dateStart, dateEnd: $dateEnd) {
        sellerId
        sellerName
        contestedLost
        topAsin
      }
    }
  `;

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const sellersToProcess = JSON.parse(JSON.stringify(dataToProcess.getTopLostBuyBox));
      const sum = sellersToProcess.reduce(
        (previousValue, currentValue) => previousValue + currentValue.contestedLost,
        0,
      );
      sellersToProcess.sort((a, b) => b?.contestedLost - a?.contestedLost);
      setTopData(sellersToProcess);
      setTotalLostSales(sum);
      setLoading(false);
    }
  };

  const [fetchData] = useLazyQuery(GET_TOP_LOST_BUYBOX_QUERY, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    variables: {
      dateStart,
      dateEnd,
    },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    fetchData();
  }, [asinCount]);

  const handleChooseRange = (r) => {
    setDateRange(r);
    setLoading(true);
    fetchData();
  };

  const handleSellerClick = (seller) => {
    setShowSellerDrawer(seller);
  };

  return (
    <Widget>
      <WidgetHeader
        sx={{ pb: 0 }}
        title="Estimated Lost Buy Box Sales"
        bottom={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <DateRangeDropdown
              ranges={dateRanges}
              initialRange={dateRange}
              onChooseRange={handleChooseRange}
            />
          </Box>
        }
      />
      <Box>
        <Box sx={{ minHeight: loading ? '276px' : '66px' }}>
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                minHeight: loading ? '276px' : '66px',
              }}
            >
              <Loading />
            </Box>
          )}
          {!loading && (
            <Box>
              <NavLink
                to="/reports/lostsales"
                style={{ textDecoration: 'underline', color: 'red' }}
              >
                <Box
                  sx={{
                    color: 'red',
                    pr: 1,
                    fontSize: '22px',
                    fontWeight: '600',
                    textAlign: 'center',
                  }}
                >
                  {Intl.NumberFormat(region, {
                    style: 'currency',
                    maximumFractionDigits: 0,
                    currency: regionCurrency,
                  }).format(totalLostSales)}
                </Box>
              </NavLink>
              {topData?.length > 0 && (
                <TableContainer sx={{ mt: 3 }}>
                  <Table size="small" data-cy="reports_lost_buybox">
                    <TableHead>
                      <StyledTableHeaderRow>
                        <StyledTableCell sx={{ fontSize: '13px' }} align="left">
                          Top 5 Lost Buy Box Recipients
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontSize: '13px' }} align="left">
                          Top ASIN
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontSize: '13px' }} align="right">
                          Amount
                        </StyledTableCell>
                      </StyledTableHeaderRow>
                    </TableHead>
                    <TableBody>
                      {topData.slice(0, 5).map((seller) => (
                        <StyledTableRow key={seller.sellerId}>
                          <StyledTableCell align="left" sx={{ fontSize: '13px' }}>
                            <Box
                              data-cy="seller_insight_linkbutton"
                              sx={{
                                textDecoration: 'underline',
                                fontWeight: 500,
                                color: (theme) => theme.colors.linkBlue,
                                cursor: 'pointer',
                              }}
                              onClick={() => handleSellerClick(seller)}
                            >
                              {seller.sellerName}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell sx={{ fontSize: '13px' }} align="left">
                            {seller.topAsin}
                          </StyledTableCell>
                          <StyledTableCell sx={{ color: 'red', fontSize: '13px' }} align="right">
                            {seller.contestedLost
                              ? `${Intl.NumberFormat(region, {
                                  style: 'currency',
                                  currency: regionCurrency,
                                  maximumFractionDigits: 0,
                                }).format(seller.contestedLost)}`
                              : '-'}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <LostBBSellerDrawer
        seller={showSellerDrawer}
        open={!!showSellerDrawer}
        onClose={() => setShowSellerDrawer(null)}
        dateStart={dateStart}
        dateEnd={dateEnd}
        rangeLabel={dateRange.label}
      />
    </Widget>
  );
};

export default LostSalesWidget;
